import { PropsWithChildren } from 'react';
import { UserSubscriptionStatus } from '@remento/types/user';
import dayjs from 'dayjs';

import { RMText } from '@/components/RMText/RMText';

import { ProjectListWrapper, StyledMyProjectSettingsPreviewRoot } from './MyProjectSettingsPreviewRoot.styles';

export type MyProjectSettingsPreviewProps = PropsWithChildren<{
  subscriptionStatus: UserSubscriptionStatus;
  subscriptionEndDate: number;
}>;

export function MyProjectSettingsPreviewRoot({
  subscriptionStatus,
  subscriptionEndDate,
  children,
}: MyProjectSettingsPreviewProps) {
  const formattedEndDate = dayjs(subscriptionEndDate).format('MM/DD/YYYY');
  const isExpired = Date.now() > subscriptionEndDate;

  return (
    <StyledMyProjectSettingsPreviewRoot>
      <RMText type="sans" size="s" color="on-surface-primary">
        {subscriptionStatus === UserSubscriptionStatus.STARTED &&
          `Your subscription is active and will be renewed on ${formattedEndDate}.`}
        {subscriptionStatus === UserSubscriptionStatus.CANCELED &&
          isExpired === true &&
          `Your subscription ended on ${formattedEndDate}.`}
        {subscriptionStatus === UserSubscriptionStatus.CANCELED &&
          isExpired === false &&
          `Your subscription is canceled and will expire on ${formattedEndDate}.`}
      </RMText>
      <ProjectListWrapper>
        <RMText type="sans" size="s" color="on-surface-primary" bold>
          {subscriptionStatus === UserSubscriptionStatus.CANCELED ? 'Expired' : 'Active'} Storytellers & Projects
        </RMText>
        {children}
      </ProjectListWrapper>
      <RMText type="sans" size="s" color="on-surface-primary">
        Questions? Contact{' '}
        <a href="mailto:support@remento.co">
          <RMText type="sans" size="s" color="primary" underline>
            support@remento.co
          </RMText>
        </a>
      </RMText>
    </StyledMyProjectSettingsPreviewRoot>
  );
}
