import { styled } from '@linaria/react';

export const StyledMyProjectSettingsPreviewRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;

export const ProjectListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;
